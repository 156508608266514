<script>
/**
 * Transactions component
 */
import api from '../../../api'
// import Statistics from './statistics.vue'
import Swal from 'sweetalert2'
// import NumberInputSpinner from "vue-number-input-spinner";
import DatePicker from "vue2-datepicker";

export default {
  components: {
    // Statistics,
    DatePicker
    // NumberInputSpinner
  },
  data() {
    return {
      transactionData: [
        {
          orderid: "#NZ1563",
          date: "28 Mar, 2020",
          billingname: "Frank Dean",
          total: "$164",
          paymentstatus: "Unpaid"
        },
        {
          orderid: "#NZ1564",
          date: "28 Mar, 2020",
          billingname: "Eddy Torres",
          total: "$141",
          paymentstatus: "Paid"
        },
        {
          orderid: "#NZ1565",
          date: "29 Mar, 2020",
          billingname: "Jamison Clark",
          total: "$123",
          paymentstatus: "Paid"
        },
        {
          orderid: "#NZ1566",
          date: "30 Mar, 2020",
          billingname: "Jewel Buckley",
          total: "$112",
          paymentstatus: "Paid"
        },
        {
          orderid: "#NZ1567",
          date: "31 Mar, 2020",
          billingname: "Jeffrey Waltz",
          total: "$105",
          paymentstatus: "Unpaid"
        },
        {
          orderid: "#NZ1568",
          date: "01 Apr, 2020",
          billingname: "Jefferson Allen",
          total: "$160",
          paymentstatus: "Chargeback"
        },
        {
          orderid: "#NZ1569",
          date: "02 Apr, 2020",
          billingname: "Paul Jones",
          total: "$183",
          paymentstatus: "Paid"
        },
        {
          orderid: "#NZ1570",
          date: "03 Apr, 2020",
          billingname: "Donald Bailey",
          total: "$146",
          paymentstatus: "Paid"
        },
        {
          orderid: "#NZ1571",
          date: "03 Apr, 2020",
          billingname: "Jimmy Barker",
          total: "$165",
          paymentstatus: "Unpaid"
        },
        {
          orderid: "#NZ1572",
          date: "04 Apr, 2020",
          billingname: "Walter Brown",
          total: "$172",
          paymentstatus: "Paid"
        }
      ],
      users: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "username", sortable: true, label: "Username" },
        { key: "email", sortable: true },
        { key: "level", sortable: true },
        // { key: "score", sortable: true, label: 'Score %' },
        { key: "statistics", sortable: true },
        { key: "actions" }
      ],
      expandStatistics: null,
      questions: [],
      answers: [],
      selectedDate: '',
      user_id: null,
      distributedSeries: {
        data: {
            labels: [],
            series: []
        },
        options: {
            height: 300,
            distributeSeries: true,
        }
      },
      daterange: []   ,
      newPassword: ''   
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.users.length;
    },
    totalScore() {
      let result = 0;
      for(let question of this.questions) {
        if(question.type == 'multiple') {
          result += question.ticks * 5
        } else {
          // if(answer.value) {
          result +=  5
          // }
        }
      }
      return result
    },
    score() {
      let result = 0;
      for(let answer of this.answers) {
        if(typeof(answer.value) == 'number') {
          result += answer.value * 5
        } else {
          if(answer.value) {
          result +=  5
          }
        }
      }
      return result
    },
  },
  mounted() {
    // Set the initial number of items
    this.selectedDate = new Date().toString()
    this.totalRows = this.users.length;
    this.getUsers()
  },
  methods: {
    deleteUser(user_id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(async result => {
        if (result.value) {
          try {
            await api.delete(`/users/${user_id}`)
            Swal.fire("Deleted!", "User have been deleted.", "success");
            this.getUsers()
          } catch (e) {
            console.log(e)
          }
        }
      });
    },
    async generateReport(val) {
      await this.getQuestions(val.level)
      await this.getAnswers(val.id)
      this.$bvModal.show(`modal-scrollable${val.id}`)
    },
    async viewUser(val) {
      this.$bvModal.show(`modal-user${val.id}`)
      // this.current_user = val
    },
    async changePassword(val) {
      this.$bvModal.show(`modal-password${val}`)
    },
    async updatePassword(val) {
      try {
        await api.patch(`users/password/${val}`, {
          password: this.newPassword
        })
      } catch (e) {
        console.log(e)
      }
      this.newPassword = ''
      this.$bvModal.hide(`modal-password${val}`)
    },
    async getQuestions(level) {
      this.questions = (await api.get('/questions')).data;
      this.questions = this.questions.filter(x => x.level == level)
    },
    async getAllAnswers(user_id) {
      this.user_id = user_id
      this.answers = (await api.get(`/users/${user_id}/answers`)).data;

      // for(let question of this.questions) {
      //   if(question.type == 'multiple') {
      //     let answerFound = this.answers.filter(x => x.questionsId == question.id)[0]
      //     if(!answerFound) {
      //       this.answers.push({
      //         questionsId: question.id,
      //         value: 0
      //       })
      //     }
      //   }     
      // }
    },
    async getAnswers(user_id) {
      this.user_id = user_id
      this.answers = (await api.get(`/users/${user_id}/answers`)).data;
      this.answers = this.answers.filter(x => new Date(x.date).toDateString() == new Date(this.selectedDate).toDateString())
    },
    async toggleExpandStatistics(val) {
      this.user_id = val
      if (this.expandStatistics != val) {
        this.expandStatistics = val;
        await this.getAllAnswers(val)

        let start_date = new Date(this.daterange[0])
        start_date.setDate(start_date.getDate() + 1);        
        let end_date = new Date(this.daterange[1])
        end_date.setDate(end_date.getDate() + 1);

        this.answers = this.answers.filter(
          x => 
          new Date(start_date).getTime() <= new Date(x.date).getTime() &&
          new Date(end_date).getTime() >= new Date(x.date).getTime()
        )
        const groups = this.answers.reduce((groups, answer) => {
          const date = answer.date.split('T')[0];
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(answer);
          return groups;
        }, {});

        const groupArrays = Object.keys(groups).map((date) => {
          return {
            date,
            answers: groups[date]
          };
        });

        let labels = []
        let series = []

        for(let group of groupArrays) {
          let result = 0;

          for(let answer of group.answers) {
            if(typeof(answer.value) == 'number') {
              result += answer.value * 5
            } else {
              if(answer.value) {
              result +=  5
              }
            }
          }
          labels.push(group.date)
          series.push(result)
        }
        labels.reverse()
        series.reverse()
        this.distributedSeries.data.labels = labels
        this.distributedSeries.data.series = series

        } else {
          this.expandStatistics = null;
        }
    },
    async getUsers() {
      this.users = (await api.get('/users')).data;
      this.users = this.users.filter(x => x.role != 'admin')
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async approveRequest(val) {
      try {
        await api.patch(`/users/${val.id}`, {
          "level": val.requestedLevel.toString(),
          "pendingLevelRequest": false
        })
        Swal.fire("Request Updated Successfully!", "Request Updated Successfully!", "success");
        await this.getUsers()
      } catch (e) {
        console.log(e)
      }
    },
    async declineRequest(val) {
      try {
        await api.patch(`/users/${val.id}`, {
          "pendingLevelRequest": false
        })
        Swal.fire("Request Updated Successfully!", "Request Updated Successfully!", "success");
        await this.getUsers()
      } catch (e) {
        console.log(e)
      }
    }
  },
  watch: {
    selectedDate() {
      this.getAnswers(this.user_id)
    },
    daterange: {
      handler() {
        this.expandStatistics = null;
        this.toggleExpandStatistics(this.user_id)
      },
      deep: true
    }
  }
  // mounted() {
  //   this.selectedDate = new Date().toString()
  // }
};
</script>

<template>
  <div class="card">
    <div class="card-body">

      <h4 class="card-title mb-4">Users</h4>
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_filter" class="dataTables_filter text-md-right">
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <div class="table-responsive">
        <b-table
          :items="users"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >       
          <template v-slot:cell(username)="row">
            <span :class="{'text-danger': row.item.pendingLevelRequest}">{{row.item.username}}</span>
          </template>
          <template v-slot:cell(statistics)="row">
                <div
                  class="text-info"
                  style="cursor: pointer"
                  @click="toggleExpandStatistics(row.item.id)"
                >
                  {{expandStatistics == row.item.id ? 'Close' : 'View'}}
                </div>
                <div
                  v-if="expandStatistics == row.item.id"
                >
                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-body" dir="ltr">
                      <h4 class="card-title mb-4">Statistics</h4>
                      <div class="form-group mb-3">
                        <label>Date Range</label>
                        <br />
                        <date-picker v-model="daterange" range append-to-body lang="en" confirm></date-picker>
                      </div>
                      <!-- Distributed series -->
                      <chartist
                        ratio="ct-chart"
                        :data="distributedSeries.data"
                        :options="distributedSeries.options"
                        type="Bar"
                      ></chartist>
                    </div>
                    <!-- end card-body -->
                  </div>
                </div>
                  <!-- <Statistics></Statistics> -->
                </div>
            <!-- {{row}} -->
            <!-- <div
              class="badge font-size-12"
              :class="{'badge-soft-danger': `${row.value}` === 'Chargeback',
              'badge-soft-success': `${row.value}` === 'Paid',
              'badge-soft-warning': `${row.value}` === 'Unpaid'}"
            >{{ row.value}}</div> -->
          </template>

          <template v-slot:cell(actions)="row">
            <button class="btn btn-sm btn-success mr-2"
            :disabled="!row.item.pendingLevelRequest"
            @click="approveRequest(row.item)">
              Approve
            </button>
            <button class="btn btn-sm btn-warning mr-2"
            :disabled="!row.item.pendingLevelRequest"
            @click="declineRequest(row.item)">
              Decline
            </button>
            <button class="btn btn-sm btn-dark mr-2" @click="viewUser(row.item)">
              View
            </button>
            <button class="btn btn-sm btn-primary mr-2" @click="generateReport(row.item)">
              Report
            </button>
            <button class="btn btn-sm btn-danger mr-2" @click="deleteUser(row.item.id)">
              Delete
            </button>
            <button class="btn btn-sm btn-light" @click="changePassword(row.item.id)">
              Change Password
            </button>
            <b-modal
              :id="`modal-password${row.item.id}`"
              title="Change Password"
              title-class="font-18"
            >
            <label for="">New Password</label>
            <input
            class="ml-2 col-8"
            type="text"
            v-model="newPassword"
            />
            <br>
            <button class="mt-4 btn btn-primary" @click="updatePassword(row.item.id)">Update</button>
            </b-modal>
            <b-modal
              :id="`modal-user${row.item.id}`"
              title="User Bio"
              title-class="font-18"
            >
            <div>
              <div class="d-flex">
                <label class="col-6">Username:</label><p class="col-6">{{row.item.username}}</p>
              </div>
              <div class="d-flex">
                <label class="col-6">Role:</label><p class="col-6">{{row.item.role}}</p>
              </div>
              <div class="d-flex">
                <label class="col-6">Email:</label><p class="col-6">{{row.item.email}}</p>
              </div>
              <div class="d-flex">
                <label class="col-6">Level:</label><p class="col-6">{{row.item.level}}</p>
              </div>
              <div class="d-flex">
                <label class="col-6">DOB:</label><p class="col-6">{{row.item.dob}}</p>
              </div>
              <div class="d-flex">
                <label class="col-6">Nationality:</label><p class="col-6">{{row.item.nationality}}</p>
              </div>
              <div class="d-flex">
                <label class="col-6">Education:</label><p class="col-6">{{row.item.education}}</p>
              </div>
              <div v-if="row.item.pendingLevelRequest" class="d-flex">
                <label class="col-6 text-danger">Requested Level:</label><p class="col-6 text-danger">{{row.item.requestedLevel}}</p>
              </div>
            </div>
            </b-modal>
            <b-modal
              :id="`modal-scrollable${row.item.id}`"
              scrollable
              title="Report"
              title-class="font-18"
            >
              <div class="row col-12" v-show="questions.length">
                <div class="mb-3">
                  <label>Select Date</label>
                  <br />
                  <date-picker class="col-12 m-0 p-0"
                  :disabled-date="(date) => date >= new Date()" v-model="selectedDate"
                  :clearable="false"
                  :first-day-of-week="1" 
                  lang="en"></date-picker>
                </div>
                <div v-if="selectedDate" class="row" v-show="questions.length">
                  <div class="">
                    <div class="card">
                      <div class="card-body">

                        <h4 class="card-title mb-4">Report - Score {{score}} / {{totalScore}}</h4>
                        
                        <div class="row mt-4">
                          <div v-for="question in questions" :key="question.id" class="col-12 row">
                            <p class="col-8">{{question.english}}</p>
                            <p class="col-4">
                              <!-- {{answers.length && answers.filter(x => x.questionsId == question.id)[0] ? answers.filter(x => x.questionsId == question.id)[0].value : ''}} -->
                              <!-- <input v-if="question.type == 'single'" type="checkbox"
                              :checked="answers.length && answers.filter(x => x.questionsId == question.id)[0] ? answers.filter(x => x.questionsId == question.id)[0].value : false"
                              style="width: 18px; height: 18px"/> -->
                              <input v-if="question.type == 'single'" type="checkbox"
                              :disabled="true"
                              :value="answers.length && answers.filter(x => x.questionsId == question.id)[0] ? answers.filter(x => x.questionsId == question.id)[0].value : false"
                              :checked="answers.length && answers.filter(x => x.questionsId == question.id)[0] ? answers.filter(x => x.questionsId == question.id)[0].value : false"
                              style="width: 18px; height: 18px"/>
                              <!-- <span v-if="question.type == 'multiple'">
                                <NumberInputSpinner :min="0" v-if="answers.length && answers.filter(x => x.questionsId == question.id)[0]" :max="question.ticks" :integerOnly="true"
                                v-model="answers.filter(x => x.questionsId == question.id)[0].value"/>   -->
                                <!-- <NumberInputSpinner v-else :min="0" :max="question.ticks" :integerOnly="true"/> -->
                              <!-- </span> -->
                              <!-- {{answers}} -->
                              <input v-if="question.type == 'multiple'" type="number" :min="0" :max="question.ticks"
                              :disabled="true"
                              :value="answers.length && answers.filter(x => x.questionsId == question.id)[0] ? answers.filter(x => x.questionsId == question.id)[0].value : false"
                              >
                              <!-- <NumberInputSpinner v-if="question.type == 'multiple'" :min="0" :max="question.ticks" :integerOnly="true"
                              v-model="answers.filter(x => x.questionsId == question.id)[0].value"/> -->
                            </p>
                          </div>
                          <!-- <div class="container">
                            <div class="row">
                                <div class="col-md-12 text-right">
                                  <button class="btn btn-success pull-right">Update</button>
                                </div>
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>      
                  </div>
                </div>
              </div>
            </b-modal>
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>