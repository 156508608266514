<script>
import Layout from "../../layouts/main";
import Users from './users';
import api from '../../../api'
import Swal from 'sweetalert2'
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    Users
  },
  data() {
    return {
      title: "Admin Dashboard",
      items: [
        {
          text: "Zawiyah "
        },
        {
          text: "Admin Dashboard",
          active: true
        }
      ],
      videoTitle: '',
      videoPosition: 0,
      videoPath: '',
      pdfPath: '',
      pdfPosition: 0,
      pdfTitle: ''
    };
  },
  methods: {
    async postVideoResource() {
      try {
        await api.post('/resources', {
          "title": this.videoTitle,
          "type": "video",
          'path': this.videoPath,
          "position": parseInt(this.videoPosition)
        })
        this.videoTitle = ''
        this.videoPosition = 0
        this.videoPath = ''
        Swal.fire("Resources Uploaded!", "Resources Uploaded Successfully", "success");
        this.$bvModal.hide('modal-center')
      } catch (e) {
        console.log(e)
      }
    },
    async postPdfResource() {
      try {
        await api.post('/resources', {
          "title": this.pdfTitle,
          "type": "video",
          'path': this.pdfPath,
          "position": parseInt(this.pdfPosition)
        })
        this.pdfTitle = ''
        this.pdfPosition = 0
        this.pdfPath = ''
        Swal.fire("Resources Uploaded!", "Resources Uploaded Successfully", "success");
        this.$bvModal.hide('modal-center')
      } catch (e) {
        console.log(e)
      }
    },
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0">{{ title }}</h4>
          <div class="page-title-right">
            <!-- <button class="btn btn-primary">Upload Resources</button> -->
            <b-button v-b-modal.modal-center variant="primary">Upload Resources</b-button>
                  <b-modal
                    id="modal-center"
                    centered
                    title="Resource Upload"
                    title-class="font-18"
                    hide-footer
                  >
              <div class="">
                <div class="card-body">
                  <h4 class="card-title">File Upload</h4>
                  <p class="card-title-desc">
                    Enter PDF URL. Visit <a href="www.keepandshare.com">www.keepandshare.com</a> to upload free PDF files.
                  </p>
                  <b-form-group
                    id="example text"
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Title"
                    label-for="title"
                  >
                    <b-form-input for="title" v-model="pdfTitle"></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="example text"
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Position"
                    label-for="position"
                  >
                    <b-form-input for="link" v-model="pdfPosition" type="number"></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="example text"
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Link"
                    label-for="link"
                  >
                    <b-form-input for="link" v-model="pdfPath"></b-form-input>
                  </b-form-group>
                  <button class="btn btn-primary" @click="postPdfResource"
                  :disabled="pdfTitle.length == 0 || pdfPath.length == 0">Upload</button>
                  <!-- <div class="custom-file">
                    <input id="customFile" type="file" class="custom-file-input" />
                    <label class="custom-file-label" for="customFile">Choose file</label>
                    <button class="btn btn-primary mt-3" @click="uploadFile">Upload</button>
                  </div> -->
                </div>
                <hr>
                <p style="text-align: center">OR</p>
                <hr>
                <div class="card-body">
                  <h4 class="card-title">Youtube Link</h4>
                  <p class="card-title-desc">
                    Enter Youtube embeded URL.
                  </p>
                  <b-form-group
                    id="example text"
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Title"
                    label-for="title"
                  >
                    <b-form-input for="title" v-model="videoTitle"></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="example text"
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Position"
                    label-for="position"
                  >
                    <b-form-input for="link" v-model="videoPosition" type="number"></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="example text"
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Link"
                    label-for="link"
                  >
                    <b-form-input for="link" v-model="videoPath"></b-form-input>
                  </b-form-group>
                  <button class="btn btn-primary" @click="postVideoResource"
                  :disabled="videoTitle.length == 0 || videoPath.length == 0">Upload</button>
                </div>
              </div>
                  </b-modal>

          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <Users />
      </div>
    </div>
  </Layout>
</template>